import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Select, Space } from '@madup-inc/mad-design';
import { useState } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Select} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState()\n  return (\n    <Select\n      type=\"single\"\n      placeholder=\"옵션 선택\"\n      value={value}\n      onChange={value => setValue(value)}\n      options={[\n        { label: \'박정후\', value: \'jung\' },\n        { label: \'이채은\', value: \'chelsea\' },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      Space,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState();
        return <Select type='single' placeholder='옵션 선택' value={value} onChange={value => setValue(value)} options={[{
          label: '박정후',
          value: 'jung'
        }, {
          label: '이채은',
          value: 'chelsea'
        }]} mdxType="Select" />;
      }}
    </Playground>
    <h2 {...{
      "id": "different-sizes"
    }}>{`Different sizes`}</h2>
    <Playground __position={2} __code={'() => {\n  const [smallValue, setSmallValue] = React.useState()\n  const [middleValue, setMiddleValue] = React.useState()\n  const [largeValue, setLargeValue] = React.useState()\n  return (\n    <Space>\n      <Select\n        size=\"small\"\n        type=\"single\"\n        value={smallValue}\n        onChange={value => setSmallValue(value)}\n        options={[\n          { label: \'박정후\', value: \'jung\' },\n          { label: \'이채은\', value: \'chelsea\' },\n        ]}\n      />\n      <Select\n        size=\"medium\"\n        type=\"single\"\n        value={middleValue}\n        onChange={value => setMiddleValue(value)}\n        options={[\n          { label: \'박정후\', value: \'jung\' },\n          { label: \'이채은\', value: \'chelsea\' },\n        ]}\n      />\n      <Select\n        size=\"large\"\n        type=\"single\"\n        value={largeValue}\n        onChange={value => setLargeValue(value)}\n        options={[\n          { label: \'박정후\', value: \'jung\' },\n          { label: \'이채은\', value: \'chelsea\' },\n        ]}\n      />\n    </Space>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      Space,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [smallValue, setSmallValue] = React.useState();
        const [middleValue, setMiddleValue] = React.useState();
        const [largeValue, setLargeValue] = React.useState();
        return <Space mdxType="Space">
        <Select size='small' type='single' value={smallValue} onChange={value => setSmallValue(value)} options={[{
            label: '박정후',
            value: 'jung'
          }, {
            label: '이채은',
            value: 'chelsea'
          }]} mdxType="Select" />
        <Select size='medium' type='single' value={middleValue} onChange={value => setMiddleValue(value)} options={[{
            label: '박정후',
            value: 'jung'
          }, {
            label: '이채은',
            value: 'chelsea'
          }]} mdxType="Select" />
        <Select size='large' type='single' value={largeValue} onChange={value => setLargeValue(value)} options={[{
            label: '박정후',
            value: 'jung'
          }, {
            label: '이채은',
            value: 'chelsea'
          }]} mdxType="Select" />
      </Space>;
      }}
    </Playground>
    <h2 {...{
      "id": "multiple-style"
    }}>{`Multiple style`}</h2>
    <Playground __position={3} __code={'<Select\n  type=\"multi\"\n  placeholder=\"옵션 선택\"\n  options={[\n    { label: \'박정후\', value: \'jung\' },\n    { label: \'이채은\', value: \'chelsea\' },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      Space,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select type='multi' placeholder='옵션 선택' options={[{
        label: '박정후',
        value: 'jung'
      }, {
        label: '이채은',
        value: 'chelsea'
      }]} mdxType="Select" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      